<template>
  <!-- eslint-disable max-len -->
  <div class="flex flex-col h-screen lg:flex-row">
    <div class="flex-shrink-0 hero-pattern lg:min-h-screen overflow-y-auto lg:w-full lg:max-w-sm">
      <div class="min-h-max relative flex flex-col lg:items-start lg:h-full py-9 px-6 lg:px-12">
        <header class="mb-5 lg:mb-12 flex justify-center lg:justify-start">
          <img
            src="https://cdn.parcelex.com.br/assets/v3/logos/parcelex-centrax-white-horizontal.svg"
            class="hidden lg:block"
            alt="Logo Parcelex para Negócios"
          />
          <img
            src="https://cdn.parcelex.com.br/assets/v3/logos/parcelex-centrax-white-vertical.svg"
            class="lg:hidden flex-shrink-0 w-40"
            alt="Logo Parcelex para Negócios"
          />
        </header>
        <px-stepper :steps="steps" :currentStep="currentStep" class="flex-grow"/>
        <button class="absolute top-5 right-5 lg:relative lg:top-auto lg:right-auto
        inline-flex py-1 px-3 lg:-ml-3 rounded-full items-center gap-1 text-sm
        text-primary-400 bg-black bg-opacity-20 lg:bg-transparent lg:hover:bg-black
        lg:hover:bg-opacity-20 hidden md:flex" @click="logoff">
          <LogoutIcon class="hidden lg:block" size="24"/>
          <span>Sair</span>
        </button>
      </div>
    </div>
    <div class="flex-grow bg-white lg:h-screen overflow-y-auto">
      <div class="w-full max-w-md mx-auto p-6 lg:py-9 lg:px-0">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { LogoutIcon } from '@vue-hero-icons/outline';
import { PxStepper } from '../../../components/custom';

export default {
  name: 'template-onboarding',
  components: {
    PxStepper,
    LogoutIcon,
  },
  props: {
    steps: Array,
    currentStep: Number,
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('onboarding', ['clearInitialData']),
    logoff() {
      this.clearInitialData();
      this.logout();
    },
  },
};
</script>
