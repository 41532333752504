<template>
  <!-- eslint-disable max-len -->
  <template-onboarding
    ref="template"
    :steps="onboardingSteps"
    :currentStep="currentOnboardingStep"
  >
    <header class="mb-6">
      <h1 class="font-bold text-xl mb-3 hidden lg:block">Dados cadastrais</h1>
      <p class="text-gray-500">
        Boas-vindas ao Parcelex para Negócios. Inicie a configuração da sua conta comercial.
      </p>
    </header>
    <div class="space-y-5">
      <div class="border rounded-md p-4 bg-emerald-50 border-emerald-400">
        <div class="flex items-center justify-between mb-1">
          <span class="font-semibold">Email</span>
          <CheckCircleIcon class="text-emerald-500" size="24"/>
        </div>
        <div>{{ username }}</div>
      </div>
      <px-input name="name" v-model="name">Nome</px-input>
      <px-input name="lastname" v-model="lastname">Sobrenome</px-input>
      <px-input
        mask="phone"
        placeholder="Somente números, com DDD"
        :maxlength="phoneRequiredLength"
        v-model="phone"
        name="phone"
      >
        Celular
      </px-input>
    </div>
    <footer class="flex justify-end space-x-3 mt-6">
      <px-button kind="primary" class="w-full md:w-auto" @click="handleClick">
        <span>Avançar</span>
        <ChevronRightIcon size="20" slot="right"/>
      </px-button>
    </footer>
  </template-onboarding>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { CheckCircleIcon, ChevronRightIcon } from '@vue-hero-icons/solid';
import TemplateOnboarding from './TemplateOnboarding.vue';
import { PxButton, PxInput } from '../../../components/custom';

export default {
  name: 'RegistrationData',
  metaInfo: {
    title: 'Dados cadastrais',
  },
  components: {
    TemplateOnboarding,
    PxButton,
    PxInput,
    CheckCircleIcon,
    ChevronRightIcon,
  },
  data: () => ({
    currentOnboardingStep: 0,
    phoneRequiredLength: 11,
    name: '',
    lastname: '',
    phone: '',
  }),
  computed: {
    ...mapState('auth', ['username', 'userId']),
    ...mapState('onboarding', ['user', 'onboardingSteps']),
  },
  methods: {
    ...mapMutations('onboarding', ['setUserData']),
    ...mapActions('onboarding', ['clearInitialData']),
    getInvalidInputList() {
      const list = [];
      if (!this.name) list.push('name');
      if (!this.lastname) list.push('lastname');
      if (!this.phone || this.phone.length !== this.phoneRequiredLength) list.push('phone');
      return list;
    },
    handleClick() {
      const invalidInputList = this.getInvalidInputList();
      if (invalidInputList.length) {
        this.$refs.template.$emit('invalidInput', invalidInputList);
      } else {
        this.setUserData({
          _id: this.userId,
          name: this.name,
          lastname: this.lastname,
          phone: this.phone,
        });
        this.$router.push('/onboarding/dados-comerciais');
      }
    },
  },
  mounted() {
    Object.entries(this.user).forEach(([key, value]) => {
      if (value) this[key] = value;
    });

    this.clearInitialData();
  },
};
</script>
